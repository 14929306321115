import React from "react";
import styled from "styled-components";
import { typography } from "styled-system";
import { Headline, Paragraph } from "./parts/typography";
import { Flex } from "./FlexBox";

import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

const AccordionTitle = styled.h3`
    font-weight: 600;
    line-height: 22px;
    ${typography}
`;

const Accordion = withStyles({
    root: {
        border: "none",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
            "&:first-child": {
                borderTop: 0,
            },
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: "rgba(0, 0, 0, 0)",
        borderBottom: "1px solid rgb(0, 0, 0)",
        borderTop: "1px solid rgb(0, 0, 0)",
        marginBottom: -1,
        minHeight: 30,
        "&$expanded": {
            "&:not(:last-child)": {
                borderBottom: 0,
            },
            minHeight: 30,
        },
        padding: 0,
        textTransform: "uppercase",
    },
    content: {
        padding: 0,
        "&$expanded": {
            margin: "0",
        },
        margin: 0,
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: "10px 0",
        display: "flex",
        flexDirection: "column",
    },
}))(MuiAccordionDetails);

export const Impressum = () => {
    const [expanded, setExpanded] = React.useState("panel1");

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Flex
            maxWidth="872px"
            flexDirection="column"
            alignSelf="flex-start"
            mb="20px"
        >
            <Headline>Impressum</Headline>
            <div>
                <Accordion
                    square
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                >
                    <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                    >
                        <AccordionTitle fontSize={["14px", "15px"]}>
                            Rechtliche Hinweise
                        </AccordionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Paragraph>
                            Die in dieser Website enthaltenen Textbeiträge und
                            Abbildungen sind urheberrechtlich geschützt. Eine
                            Vervielfältigung und Verbreitung des Auftritts oder
                            der Inhalte einzelner Seiten darf nur mit
                            Genehmigung der Copyright Inhaber vorgenommen
                            werden.
                        </Paragraph>
                        <Paragraph>
                            Trotz sorgfältiger inhaltlicher Kontrolle übernehmen
                            wir keine Haftung für die Inhalte externer Links.
                            Für den Inhalt der verlinkten Seiten sind
                            ausschließlich deren Betreiber verantwortlich.
                        </Paragraph>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    square
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                >
                    <AccordionSummary
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                    >
                        <AccordionTitle fontSize={["14px", "15px"]}>
                            Schlichtungsverfahren
                        </AccordionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Paragraph>
                            Wir sind stets bestrebt, etwaige
                            Meinungsverschiedenheiten mit unseren Bauherren
                            einvernehmlich beizulegen. Hierzu nehmen wir in
                            geeigneten Fällen und vorbehaltlich der
                            gegebenenfalls notwendigen Zustimmung unseres
                            Haftpflichtversicherers auc h an einem
                            Schlichtungsverfahren vor der sachkundig und
                            paritätisch besetzten Schlichtungsstelle der
                            Architektenkammer Berlin nicht jedoch vor einer
                            Verbraucherschlichtungsstelle im Sinne des VSBG
                            teil.
                        </Paragraph>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    square
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                >
                    <AccordionSummary
                        aria-controls="panel3d-content"
                        id="panel3d-header"
                    >
                        <AccordionTitle fontSize={["14px", "15px"]}>
                            Widerspruch Werbe-Mails
                        </AccordionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Paragraph>
                            Der Nutzung von im Rahmen der Impressumspflicht
                            veröffentlichten Kontaktdaten zur Übersendung von
                            nicht ausdrücklich angeforderter Werbung und
                            Informationsmaterialien wird hiermit widersprochen.
                        </Paragraph>
                        <Paragraph>
                            Die Betreiber der Seiten behalten sich ausdrücklich
                            rechtliche Schritte im Falle der unverlangten
                            Zusendung von Werbeinformationen, etwa durch Spam E
                            Mails, vor.
                        </Paragraph>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    square
                    expanded={expanded === "panel4"}
                    onChange={handleChange("panel4")}
                >
                    <AccordionSummary
                        aria-controls="panel4d-content"
                        id="panel4d-header"
                    >
                        <AccordionTitle fontSize={["14px", "15px"]}>
                            Berufsrechtliche Informationen
                        </AccordionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Paragraph>
                            Geschäftsführender Gesellschafter:
                            <br />
                            Dipl.-Ing. Architekt Klaus Kaiser
                        </Paragraph>
                        <Paragraph>
                            Die IGK BERLIN MBH ist in das
                            Gesellschaftsverzeichnis der Berufsgesellschaft bei
                            der Architektenkammer Berlin eingetragen.
                        </Paragraph>
                        <Paragraph>
                            Amtsgericht Berlin Charlottenburg
                            <br />
                            Registernummer HRB 214342 B
                        </Paragraph>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    square
                    expanded={expanded === "panel5"}
                    onChange={handleChange("panel5")}
                >
                    <AccordionSummary
                        aria-controls="panel5d-content"
                        id="panel5d-header"
                    >
                        <AccordionTitle fontSize={["14px", "15px"]}>
                            IGKBERLINMBH
                        </AccordionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Paragraph>
                            Ingenieur- und Architektengesellschaft Kaiser
                            <br />
                            Berlin mbH
                            <br />
                            Holbeinstraße 41
                            <br />
                            D-12203 Berlin
                            <br />T +49 30 890 40400
                            <br />F +49 30 890 40 427
                            <br />
                            <a
                                style={{
                                    color: "#000",
                                    textDecoration: "none",
                                }}
                                href="mailto: info@igk-berlin.de"
                            >
                                info@igk-berlin.de
                            </a>
                        </Paragraph>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    square
                    expanded={expanded === "panel6"}
                    onChange={handleChange("panel6")}
                >
                    <AccordionSummary
                        aria-controls="panel6d-content"
                        id="panel6d-header"
                    >
                        <AccordionTitle fontSize={["14px", "15px"]}>
                            Website
                        </AccordionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Paragraph>
                            Design: Sandra Pieroni
                            <br />
                            Programmierung: Evzen Pomschar
                        </Paragraph>
                        <Paragraph>© IGKBERLINMBH</Paragraph>
                    </AccordionDetails>
                </Accordion>
            </div>
        </Flex>
    );
};

export default Impressum;
