import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Impressum from "../components/impressum";

import { Flex, Box } from "../components/FlexBox";
import { Headline } from "../components/parts/typography";
import { colors } from "../utilities/colors";

const Link = styled.a`
    text-decoration: none;
    color: #000;
    &:hover {
        color: ${colors.red};
    }
`;

const Kontakt = () => {
    return (
        <Layout contentTopMargin="0">
            <Flex
                flexDirection="column"
                alignSelf="flex-start"
                width="100%"
                mb={["64px", "95px"]}
            >
                <Headline>Kontakt</Headline>
                <Flex flexDirection={["column", "row"]}>
                    <Box
                        flex={["1 0 100%", "1 0 50%"]}
                        lineHeight={["16px", "22px"]}
                        pr={[0, "18px"]}
                        fontSize={["15px"]}
                    >
                        <Box fontWeight="600">
                            <span>IGKBERLINMBH</span>
                        </Box>
                        <Box fontWeight={["600", "500"]}>
                            <span>
                                Architekten - und Ingenieurgesellschaft Kaiser
                                mbH
                            </span>
                        </Box>
                        <Box fontWeight={["400", "300"]} lineHeight={["22px"]}>
                            <span>Holbeinstrasse 41</span>
                            <br />
                            <span>12203 Berlin | Deutschland</span>
                            <br />
                            <span>T +49 30 890 40 400</span>
                            <br />
                            <span>F +49 30 890 40 427</span>
                            <br />
                        </Box>
                        <Link href="mailto:info@igk-berlin.de">
                            info@igk-berlin.de
                        </Link>
                        <span> | </span>
                        <Link href="https://www.igk-berlin.de">
                            www.igk-berlin.de
                        </Link>
                    </Box>
                </Flex>
            </Flex>
            <Impressum />
        </Layout>
    );
};

export default Kontakt;
